<template>
    <div>
        <table :style='{"width":"100%", "font-size":"7pt", "border-collapse": "collapse", "line-height": "normal",}'>
            <tr :style='{"text-align":"center"}'>
                <td :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}' width="4%">
                    Id
                </td>
                <td :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'>
                    Detalles
                </td>
                <td :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}' width="6%">
                    Dura
                </td>
                <td :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}' width="15%">
                    Horas
                </td>
                <td :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}' width="20%">
                    Flujo
                </td>
            </tr>

            <tr
                v-for="itm in datos.todas"
                :key="itm.id"
            >
                <td :style='{...estilos.tablaBorde, ...{"padding":"0px 3px 0px 3px"}}'>
                    <div :style='{"font-size":"5pt"}'>{{itm.id}}</div>
                </td>
                <td :style="estilos.tablaBorde">
                    <div :style='{"line-height": "normal", "padding":"0px 0px 0px 5px"}'>
                        <div><b>{{itm.tipoText}}<span v-if="itm.tipo == 99"> {{itm.tipoOtro}}</span>:</b> {{itm.contenido}}</div>
                        <div :style='{"font-size":"5pt"}'>{{itm.observaciones}}</div>
                    </div>
                </td>
                <td :style="estilos.tablaBorde">
                    <div :style="{'text-align': 'center'}">
                        {{itm.duracion}} hr
                    </div>
                </td>
                <td :style="estilos.tablaBorde">
                    <div :style='{"text-align": "center", "line-height": "normal", "font-size":"6pt"}'>
                        De {{itm.inicio.split(' ')[1]}}<br/>A {{itm.termino.split(' ')[1]}}
                    </div>
                </td>
                <td :style="estilos.tablaBorde">
                    <div :style='{"text-align": "center", "line-height": "normal", "font-size":"6pt"}'>
                        <div>
                            {{itm.flujo}} ml/Hr
                        </div>
                        <div v-if="itm.terminado">
                            Tot: {{$numeral(itm.flujo * itm.duracion).format('0,0.00')}}
                        </div>
                    </div>
                </td>
            </tr>
        </table>
        
    </div>
</template>

<script>
import { printEnfe } from '@/mixins/enfermeria/printEnfeMixin.js'
export default {
    mixins: [printEnfe],
    data:()=>({
        
    }),

}
</script>

<style>

</style>